import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import {Row, Col} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {Form} from 'reactstrap';
import PWPGeoUtil from '../util/PWPGeoUtil';
import EventResult from './EventResult';
import isBot from 'isbot';
import Helmet from 'react-helmet';
import { Redirect } from 'react-router-dom'


const winston = require('winston');
winston.level = process.env.REACT_APP_LOG_LEVEL;
const consoleTransport = new winston.transports.Console();
winston.add(consoleTransport);


class SingleEvent extends Component {


    constructor (props) {

        super(props);

        this.state = {
            center: {
            },
            mapKey: 1,
            eventList: [],
            zoom: 13,
            bounds: {},
            currentLocation: {},
            MasterEvent: {
                Id: 'A'
            },
            MasterEvents: [],
            listVersion: 0,
            mapClass: 'mapContainer',
            defaultIndex: 0,
            notFound: false
        };

        this.googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

        this.geoutil = new PWPGeoUtil();
        this.generateGoogleLink = this.generateGoogleLink.bind(this);

    }

    async componentDidMount() {
        let geoutil = new PWPGeoUtil();
        let event = await geoutil.loadSingleEvent(this.props.eventId);
        if (!event || !event.Coordinates) {
            this.setState({
                notFound: true
            });
        }
        else {
            let masterEvent = await geoutil.loadSingleMasterEvent(event.MasterEventId);

            this.setState({
                center: {
                    lat: event.Coordinates.lat,
                    lng: event.Coordinates.lng
                },
                mapKey: 2,
                zoom: 16,
                eventList: [event],
                MasterEvent: masterEvent
            });
        }
    }

    displayEventDetails () {
        if (this.state.eventList[0]) {
            let event = this.state.eventList[0];
            return (
                <div>
                    <EventResult
                        eventData={event}
                        displayType="single_event"
                        locationNumber={1}
                        className="eventResult"
                    /><br/>
                </div>
                    );
        }
        else {
            return (<div></div>);
        }

    }


    generateGoogleLink(event) {
        if (event) {
            let paramString = '';
            paramString = paramString + encodeURIComponent(event.Address.Street);
            paramString = paramString + '+' +encodeURIComponent(event.Address.State);
            paramString = paramString + '+' +encodeURIComponent(event.Address.City);


            let finalUrl = 'https://www.google.com/maps/search/?api=1&query='+paramString;

            winston.debug('Google Link: '+finalUrl);

            return (
                <a href={finalUrl} target='_blank' rel="noopener noreferrer">Directions from Google</a>
            );
        }
    }



    render () {
        if (this.state.notFound) {
            return (
                <div>
                    <Redirect to="/404"/>
                </div>
            );
        }
        let description = '';
        if (this.state.eventList[0] && this.state.eventList[0].Description) {
            description = this.state.eventList[0].Description.replace(/\n/g, ' ');
            description = description.replace(/\\/g, '');
        }
        return (
            <div className="findEventsPanel">
                {
                    this.state.eventList[0] &&
                    <div>
                        <Helmet>
                            <title>{this.state.eventList[0].Name} - PoliticalWatchParty.com</title>
                            <meta name="description" content={description}/>
                            <meta property="og:title" content={this.state.eventList[0].Name+' - PoliticalWatchParty.com'}/>
                        </Helmet>
                    <script type="application/ld+json">{`
                        {
                            "@context": "https://schema.org",
                            "@type": "Event",
                            "name": "${this.state.eventList[0].Name}",
                            "startDate": "${this.state.eventList[0].Time}",
                            "location": {
                                "@type": "Place",
                                "name": "${this.state.eventList[0].LocationName}",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "${this.state.eventList[0].Address.Street}",
                                    "addressLocality": "${this.state.eventList[0].Address.City}",
                                    "postalCode": "${this.state.eventList[0].Address.ZipCode}",
                                    "addressRegion": "${this.state.eventList[0].Address.State}",
                                    "addressCountry": "US"
                                }
                            },
                            "description": "${description}"
                        }
                    `}</script>
                    </div>
                }
                <Form>
                <Row>
                    <Col sm={6} md={6} lg={6}>
                        <Row>
                            <Col sm={12} md={12} lg={12}>

                                <h3 className="masterEventName">{this.state.MasterEvent.Name}</h3>
                                <h4 className="eventDateTime">{this.geoutil.getMasterEventDateString(this.state.MasterEvent.EventTime)}</h4>


                                <ReactMarkdown source={this.state.MasterEvent.Description} escapeHtml={true}/>

                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                {this.displayEventDetails()}
                            </Col>
                        </Row>
                    </Col>
                    {!isBot(navigator.userAgent) &&
                    <Col sm={6} md={6} lg={6}>
                        <div className={this.state.mapClass}>

                            <GoogleMapReact bootstrapURLKeys={{key: this.googleMapsKey}}
                                            center={this.state.center}
                                            key={this.state.mapKey}
                                            onChange={this.mapMoved}
                                            zoom={this.state.zoom}
                                            onGoogleApiLoaded={this.mapLoaded}
                                            yesIWantToUseGoogleMapApiInternals={true}>
                                {this.state.eventList.map((e, i) => {
                                    return (
                                        <EventResult
                                            lat={e.Coordinates.lat}
                                            lng={e.Coordinates.lng}
                                            key={i}
                                            locationNumber={i + 1}
                                            eventData={e}
                                            distance={e.distanceString}
                                            displayType="numberOnly"
                                            displayNumberSize="small"
                                        />
                                    )
                                })}
                            </GoogleMapReact>
                            <br/>
                            {this.generateGoogleLink(this.state.eventList[0])}
                        </div>
                    </Col>
                    }

                </Row>
                </Form>
            </div>
        );
    }
}

export default SingleEvent;