// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Event-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "MasterEvents-dev",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "EventAPI",
            "endpoint": "https://b6byvc6tk0.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "MasterEvents",
            "endpoint": "https://nurchtolha.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "politicalwatchparty-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1jidu4ybs6j7i.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:aef8f5e5-23a3-4f4f-a410-0875bff7591b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_m6w8sdloY",
    "aws_user_pools_web_client_id": "3eos6a7tlqsjshdpnbqr1mdpn2",
    "oauth": {}
};


export default awsmobile;
